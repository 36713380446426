import { createRouter, createWebHashHistory } from 'vue-router';
import ScriptStatus from '@/views/Home/ScriptStatus.vue';
const routes = [
    { path: '/', redirect: '/login' },
    {
        path: '/login',
        component: () => import('@/views/LoginAndRegister.vue'),
        name: 'login',
        meta: {
            needToken: false,
        },
    },
    {
        path: '/foundPwd',
        component: () => import('@/views/FoundPwd.vue'),
        name: 'foundPwd',
        meta: {
            needToken: false,
        },
    },
    {
        path: '/register',
        component: () => import('@/views/Register.vue'),
        name: 'register',
        meta: {
            needToken: false,
        },
    },
    {
        path: '/share',
        component: () => import('@/views/Home/SharePage.vue'),
        name: 'sharePage',
        meta: {
            needToken: false,
        },
    },
    {
        name: 'templateList',
        path: '/templateList',
        component: () => import('@/views/Home/TemplateList.vue'),
        meta: {
            needToken: true,
        },
    },
    {
        name: 'userSet',
        path: '/userSet',
        component: () => import('@/views/Home/UserSet.vue'),
        meta: {
            needToken: true,
        },
    },
    {
        path: '/home-index',
        component: () => import('@/views/Home/HomeIndex.vue'),
        children: [
            {
                name: 'dashBoard',
                path: '',
                component: () => import('@/views/Home/DashBoard.vue'),
                meta: {
                    needToken: true,
                },
            },
            {
                name: 'measureDemarcate',
                path: 'measure-demarcate',
                component: () => import('@/views/Home/MeasureDemarcate.vue'),
                meta: {
                    needToken: true,
                    keepAlive: true,
                },
                children: [
                    {
                        name: 'rightCont',
                        path: 'rightCont',
                        component: () => import('@/components/MeasureDemarcate/RightCont.vue'),
                        meta: {
                            needToken: true,
                            keepAlive: true,
                        },
                    },
                    {
                        name: 'recordingEngine',
                        path: 'recordingEngine',
                        component: () => import('@/components/MeasureDemarcate/RecordingEngine.vue'),
                        meta: {
                            needToken: true,
                            keepAlive: true,
                        },
                    },
                ],
            },
            {
                name: 'domainControl',
                path: 'domainControl',
                component: () => import('@/views/Home/DomainControl.vue'),
                meta: {
                    needToken: true,
                },
            },
            {
                name: 'yukong',
                path: 'yukong',
                component: () => import('@/views/Home/YUkong.vue'),
                meta: {
                    needToken: true,
                    keepAlive: true,
                },
            },
            {
                name: 'fileList',
                path: 'fileList',
                component: () => import('@/views/Home/FileList.vue'),
                meta: {
                    needToken: true,
                },
            },
            {
                name: 'script',
                path: 'script',
                component: ScriptStatus,
                meta: {
                    needToken: true,
                },
            },
            {
                name: 'experiment',
                path: 'experiment',
                component: () => import('@/views/Home/ExperimentList.vue'),
                meta: {
                    needToken: true,
                },
            },
        ],
    },
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return {
            el: '#app',
            top: 0,
            behavior: 'smooth',
        };
    },
});
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// import { getProp } from '@/utils/cookies';
NProgress.configure({
    easing: 'ease',
    speed: 1000,
    showSpinner: false,
    trickleSpeed: 200,
    minimum: 0.3, // 初始化时的最小百分比
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach((_to, _from, next) => {
    NProgress.start();
    // if (_to.meta.needToken) {
    //   if (getProp('token')) {
    //     next();
    //   } else {
    //     next({
    //       name: 'login',
    //       query: {
    //         redirect: _to.path,
    //       },
    //     });
    //   }
    // } else {
    //   next();
    // }
    // return true;
    // NProgress.start();
    next();
});
router.afterEach(() => {
    NProgress.done();
});
// export default router;
export default router;
